<template>
  <b-container class="main-container col">
    <b-card class="gradient-background ripple-background">
      <div class="d-flex justify-content-center">
        <div
          class="col-2 border border-secondary rounded-lg mr-3 bg-white"
          style="width: 70vw; max-height: 83.9vh"
        >
          <div class="column-title font-large-1 text-center text-primary mt-2">
            <h2 class="column-title-text">Order Set</h2>
            <hr class="hr-title-card" />
          </div>
          <draggable
            class="dragArea"
            :list="orders"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
            :clone="clone"
            :move="handleMove"
            :group="{ name: 'orders', pull: pullFunction, put: false }"
            style="max-height: 70vh; overflow-y: auto"
          >
            <div v-for="element in orders" :key="element.id">
              <div
                text-variant="white"
                class="text-center order-card"
                style="padding: 0px"
              >
                <template>
                  <div class="element-header align-items-center w-100">
                    <div class="element-header-title">{{ element.name }}</div>
                    <div
                      v-b-tooltip.hover
                      title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                      class="ml-1 info-icon"
                    >
                      <b-icon icon="info-circle-fill"></b-icon>
                    </div>
                  </div>
                </template>
                <span class="price-text"> {{ element.price }} MR</span>
              </div>
            </div>
          </draggable>
        </div>
        <!-- Day 1 -->
        <div
          class="col-2 border border-secondary rounded-lg mr-3 bg-white"
          style="width: 70vw; max-height: 83.9vh"
        >
          <div class="column-title font-large-1 text-center text-primary mt-2">
            <h2 class="column-title-text">Day 1</h2>
            <hr class="hr-title-card" />
          </div>
          <div v-for="(day, index) in day1" :key="index">
            <h4
              class="column-title-text2 d-flex align-items-center justify-content-center"
            >
              {{ day.name.split('_')[0] }}
            </h4>
            <div
              v-for="(timeslot, propertyName, index) in day.cart"
              :key="index"
              v-bind="timeslot"
            >
              <div text-variant="black" class="text-center time-card">
                <draggable
                  class="dragArea-timeslot"
                  :list="timeslot"
                  group="orders"
                  v-bind="dragOptions"
                  @start="drag = true"
                  @end="drag = false"
                  :id="day.name + '.' + propertyName"
                  style="min-width: 13vw"
                >
                  <div v-for="element in timeslot" :key="element.id">
                    <div text-variant="white" class="text-center order-card">
                      <template>
                        <div
                          class="element-header d-flex align-items-center w-100"
                        >
                          {{ element.name }}
                          <b-icon
                            v-if="isItemCopy(element)"
                            icon="trash-fill"
                            class="ml-auto text-danger"
                            style="cursor: pointer"
                            @click="
                              deleteItem(day.name, propertyName, element.id)
                            "
                            title="Remove item"
                          ></b-icon>
                          <div
                            v-b-tooltip.hover
                            title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                            class="ml-1 info-icon"
                          >
                            <b-icon icon="info-circle-fill"></b-icon>
                          </div>
                        </div>
                      </template>
                      <div class="price-text">{{ element.price }} MYR</div>
                    </div>
                  </div>
                </draggable>
              </div>
            </div>
          </div>
        </div>
        <!-- Day 2 -->
        <div
          class="col-2 border border-secondary rounded-lg mr-3 bg-white"
          style="width: 70vw; max-height: 83.9vh"
        >
          <div class="column-title font-large-1 text-center text-primary mt-2">
            <h2 class="column-title-text">Day 2</h2>
            <hr class="hr-title-card" />
          </div>
          <div style="overflow-y: auto; max-height: 65vh">
            <div v-for="(day, index) in day2" :key="index" class="mb-1">
              <h4
                class="column-title-text2 d-flex align-items-center justify-content-center"
              >
                {{ day.name.split('_')[0] }}
              </h4>
              <div
                v-for="(timeslot, propertyName, index) in day.cart"
                :key="index"
                v-bind="timeslot"
              >
                <div
                  v-if="timeslot.length > 0 && timeslot[0].isFixed"
                  text-variant="black"
                  class="text-center time-card"
                >
                  <div v-for="element in timeslot" :key="element.id">
                    <div
                      text-variant="white"
                      class="text-center order-card-dis"
                      v-b-tooltip.hover="'This order is fixed'"
                    >
                      <template>
                        <div
                          class="element-header-dis d-flex align-items-center w-100"
                        >
                          {{ element.name }}
                          <b-icon
                            v-if="isItemCopy(element)"
                            icon="trash-fill"
                            class="ml-auto text-danger"
                            style="cursor: pointer"
                            @click="
                              deleteItem(day.name, propertyName, element.id)
                            "
                            title="Remove item"
                          ></b-icon>
                          <div
                            v-b-tooltip.hover
                            title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                            class="ml-1 info-icon"
                          >
                            <b-icon icon="info-circle-fill"></b-icon>
                          </div>
                        </div>
                      </template>
                      <div class="price-text">{{ element.price }} MYR</div>
                    </div>
                  </div>
                </div>
                <div v-else text-variant="black" class="text-center time-card">
                  <draggable
                    class="dragArea-timeslot"
                    :list="timeslot"
                    group="orders"
                    v-bind="dragOptions"
                    @start="drag = true"
                    @end="drag = false"
                    :id="day.name + '.' + propertyName"
                    style="min-width: 13vw"
                  >
                    <div v-for="element in timeslot" :key="element.id">
                      <div text-variant="white" class="text-center order-card">
                        <template>
                          <div
                            class="element-header d-flex align-items-center w-100"
                          >
                            {{ element.name }}
                            <b-icon
                              v-if="isItemCopy(element)"
                              icon="trash-fill"
                              class="ml-auto text-danger"
                              style="cursor: pointer"
                              @click="
                                deleteItem(day.name, propertyName, element.id)
                              "
                              title="Remove item"
                            ></b-icon>
                            <div
                              v-b-tooltip.hover
                              title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                              class="ml-1 info-icon"
                            >
                              <b-icon icon="info-circle-fill"></b-icon>
                            </div>
                          </div>
                        </template>
                        <div class="price-text">{{ element.price }} MYR</div>
                      </div>
                    </div>
                  </draggable>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Day 3 -->
        <div
          class="col-2 border border-secondary rounded-lg mr-3 bg-white"
          style="width: 70vw; max-height: 83.9vh"
        >
          <div class="column-title font-large-1 text-center text-primary mt-2">
            <h2 class="column-title-text">Day 3</h2>
            <hr class="hr-title-card" />
          </div>
          <div style="overflow-y: auto; max-height: 65vh">
            <div v-for="(day, index) in day3" :key="index" class="mb-1">
              <h4
                class="column-title-text2 d-flex align-items-center justify-content-center"
              >
                {{ day.name.split('_')[0] }}
              </h4>
              <div
                v-for="(timeslot, propertyName, index) in day.cart"
                :key="index"
                v-bind="timeslot"
              >
                <div
                  v-if="timeslot.length > 0 && timeslot[0].isFixed"
                  text-variant="black"
                  class="text-center time-card"
                >
                  <div v-for="element in timeslot" :key="element.id">
                    <div
                      text-variant="white"
                      class="text-center order-card-dis"
                      v-b-tooltip.hover="'This order is fixed'"
                    >
                      <template>
                        <div
                          class="element-header-dis d-flex align-items-center w-100"
                        >
                          {{ element.name }}
                          <b-icon
                            v-if="isItemCopy(element)"
                            icon="trash-fill"
                            class="ml-auto text-danger"
                            style="cursor: pointer"
                            @click="
                              deleteItem(day.name, propertyName, element.id)
                            "
                            title="Remove item"
                          ></b-icon>
                          <div
                            v-b-tooltip.hover
                            title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                            class="ml-1 info-icon"
                          >
                            <b-icon icon="info-circle-fill"></b-icon>
                          </div>
                        </div>
                      </template>
                      <div class="price-text">{{ element.price }} MYR</div>
                    </div>
                  </div>
                </div>
                <div v-else text-variant="black" class="text-center time-card">
                  <draggable
                    class="dragArea-timeslot"
                    :list="timeslot"
                    group="orders"
                    v-bind="dragOptions"
                    @start="drag = true"
                    @end="drag = false"
                    :id="day.name + '.' + propertyName"
                    style="min-width: 13vw"
                  >
                    <div v-for="element in timeslot" :key="element.id">
                      <div text-variant="white" class="text-center order-card">
                        <template>
                          <div
                            class="element-header d-flex align-items-center w-100"
                          >
                            {{ element.name }}
                            <b-icon
                              v-if="isItemCopy(element)"
                              icon="trash-fill"
                              class="ml-auto text-danger"
                              style="cursor: pointer"
                              @click="
                                deleteItem(day.name, propertyName, element.id)
                              "
                              title="Remove item"
                            ></b-icon>
                            <div
                              v-b-tooltip.hover
                              title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                              class="ml-1 info-icon"
                            >
                              <b-icon icon="info-circle-fill"></b-icon>
                            </div>
                          </div>
                        </template>
                        <div class="price-text">{{ element.price }} MYR</div>
                      </div>
                    </div>
                  </draggable>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Day 4 -->
        <div
          class="col-2 border border-secondary rounded-lg mr-3 bg-white"
          style="width: 70vw; max-height: 83.9vh"
        >
          <div class="column-title font-large-1 text-center text-primary mt-2">
            <h2 class="column-title-text">Day 4</h2>
            <hr class="hr-title-card" />
          </div>
          <div style="overflow-y: auto; max-height: 65vh">
            <div v-for="(day, index) in day4" :key="index" class="mb-1">
              <h4
                class="column-title-text2 d-flex align-items-center justify-content-center"
              >
                {{ day.name.split('_')[0] }}
              </h4>
              <div
                v-for="(timeslot, propertyName, index) in day.cart"
                :key="index"
                v-bind="timeslot"
              >
                <div
                  v-if="timeslot.length > 0 && timeslot[0].isFixed"
                  text-variant="black"
                  class="text-center time-card"
                >
                  <div v-for="element in timeslot" :key="element.id">
                    <div
                      text-variant="white"
                      class="text-center order-card-dis"
                      v-b-tooltip.hover="'This order is fixed'"
                    >
                      <template>
                        <div
                          class="element-header-dis d-flex align-items-center w-100"
                        >
                          {{ element.name }}
                          <b-icon
                            v-if="isItemCopy(element)"
                            icon="trash-fill"
                            class="ml-auto text-danger"
                            style="cursor: pointer"
                            @click="
                              deleteItem(day.name, propertyName, element.id)
                            "
                            title="Remove item"
                          ></b-icon>
                          <div
                            v-b-tooltip.hover
                            title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                            class="ml-1 info-icon"
                          >
                            <b-icon icon="info-circle-fill"></b-icon>
                          </div>
                        </div>
                      </template>
                      <div class="price-text">{{ element.price }} MYR</div>
                    </div>
                  </div>
                </div>
                <div v-else text-variant="black" class="text-center time-card">
                  <draggable
                    class="dragArea-timeslot"
                    :list="timeslot"
                    group="orders"
                    v-bind="dragOptions"
                    @start="drag = true"
                    @end="drag = false"
                    :id="day.name + '.' + propertyName"
                    style="min-width: 13vw"
                  >
                    <div v-for="element in timeslot" :key="element.id">
                      <div text-variant="white" class="text-center order-card">
                        <template>
                          <div
                            class="element-header d-flex align-items-center w-100"
                          >
                            {{ element.name }}
                            <b-icon
                              v-if="isItemCopy(element)"
                              icon="trash-fill"
                              class="ml-auto text-danger"
                              style="cursor: pointer"
                              @click="
                                deleteItem(day.name, propertyName, element.id)
                              "
                              title="Remove item"
                            ></b-icon>
                            <div
                              v-b-tooltip.hover
                              title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                              class="ml-1 info-icon"
                            >
                              <b-icon icon="info-circle-fill"></b-icon>
                            </div>
                          </div>
                        </template>
                        <div class="price-text">{{ element.price }} MYR</div>
                      </div>
                    </div>
                  </draggable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>

    <b-card class="mx-5">
      <div class="d-flex justify-content-between">
        <div class="badge-dark total-price">Total: {{ getTotal }} MYR</div>
        <div>
          <b-button variant="info" @click="refreshPage()" class="mx-2">
            <feather-icon icon="RefreshCwIcon" size="15" class="text-white" />
            <span> Reset </span>
          </b-button>
          <b-button variant="success" @click.prevent="submitOrder()">
            <feather-icon
              icon="ShoppingCartIcon"
              size="15"
              class="text-white"
            />
            <span> Submit </span>
          </b-button>
        </div>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import draggable from 'vuedraggable';
import 'vue-select/dist/vue-select.css';
import flatPickr from 'vue-flatpickr-component';

export default {
  name: 'DragAndDrop',
  components: {
    draggable,
    flatPickr,
  },
  data() {
    return {
      orders: [
        {
          name: 'Order1',
          id: 1,
          price: 200,
        },
        {
          name: 'Order2',
          id: 2,
          price: 300,
        },
        {
          name: 'Order3',
          id: 3,
          price: 100,
        },
        {
          name: 'Order4',
          id: 4,
          price: 100,
        },
        {
          name: 'Order5',
          id: 5,
          price: 100,
        },
        {
          name: 'Order6',
          id: 6,
          price: 100,
        },
        {
          name: 'Order7',
          id: 7,
          price: 100,
        },
        {
          name: 'Order8',
          id: 8,
          price: 100,
        },
        {
          name: 'Order9',
          id: 9,
          price: 100,
        },
        {
          name: 'Order10',
          id: 10,
          price: 100,
        },
        {
          name: 'Order11',
          id: 11,
          price: 100,
        },
        {
          name: 'Order12',
          id: 12,
          price: 100,
        },
      ],
      day1: [
        {
          id: 1,
          name: 'Night_1',
          cart: {
            top: [],
            bottom: [],
          },
        },
      ],
      day2: [
        {
          id: 1,
          name: 'Morning_2',
          cart: {
            top: [],
            bottom: [],
          },
        },
        {
          id: 1,
          name: 'Noon_2',
          cart: {
            top: [],
            bottom: [],
          },
        },
        {
          id: 1,
          name: 'Night_2',
          cart: {
            top: [],
            bottom: [
              {
                name: 'Order12',
                id: 12,
                price: 100,
                isFixed: true,
              },
            ],
          },
        },
      ],
      day3: [
        {
          id: 1,
          name: 'Morning_3',
          cart: {
            top: [],
            bottom: [],
          },
        },
        {
          id: 1,
          name: 'Noon_3',
          cart: {
            top: [
              {
                name: 'Order8',
                id: 8,
                price: 100,
                isFixed: true,
              },
            ],
            bottom: [],
          },
        },
        {
          id: 1,
          name: 'Night_3',
          cart: {
            top: [],
            bottom: [],
          },
        },
      ],
      day4: [
        {
          id: 1,
          name: 'Morning_4',
          cart: {
            top: [
              {
                name: 'Order2',
                id: 2,
                price: 300,
                isFixed: true,
              },
            ],
            bottom: [],
          },
        },
        {
          id: 1,
          name: 'Noon_4',
          cart: {
            top: [],
            bottom: [],
          },
        },
        {
          id: 1,
          name: 'Night_4',
          cart: {
            top: [],
            bottom: [],
          },
        },
      ],
      idGlobal: 0,
      controlOnStart: true,
    };
  },
  computed: {
    getTotal() {
      let total = 0;
      const days = [this.day1, this.day2, this.day3, this.day4];
      days.forEach((day) => {
        day.forEach((dayItem) => {
          Object.values(dayItem.cart).forEach((timeslot) => {
            timeslot.forEach((order) => {
              total += order.price;
            });
          });
        });
      });
      return total;
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },

  mounted() {
    window.addEventListener('beforeunload', function (e) {
      e.preventDefault();
      e.returnValue = 'You have unsaved changes!';
    });
  },

  methods: {
    clone({ name, price }) {
      const newItem = { name, id: this.idGlobal++, price, isCopy: true };
      return newItem;
    },
    submitOrder() {
      this.$swal({
        title: 'Success!',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      });
    },
    pullFunction() {
      return 'clone';
    },
    isItemCopy(item) {
      // Safely check for isCopy, return false if undefined
      return !!item.isCopy;
    },
    deleteItem(dayName, timeslotName, itemId) {
      const days = [this.day1, this.day2, this.day3, this.day4];
      let selectedDay = null;
      days.some((day) => {
        const found = day.find((d) => d.name === dayName);
        if (found) {
          selectedDay = found;
          return true;
        }
        return false;
      });

      if (!selectedDay) {
        console.error(`Day ${dayName} not found.`);
        return;
      }

      const timeslot = selectedDay.cart[timeslotName];
      if (!timeslot) {
        console.error(`Timeslot ${timeslotName} not found in day ${dayName}.`);
        return;
      }

      const index = timeslot.findIndex((item) => item.id === itemId);
      if (index === -1) {
        console.error(
          `Item with ID ${itemId} not found in timeslot ${timeslotName}.`
        );
        return;
      }

      if (timeslot[index].isCopy) {
        timeslot.splice(index, 1);
      } else {
        console.error('Cannot delete original items.');
      }
    },

    refreshPage() {
      this.day1 = [
        {
          id: 1,
          name: 'Night_1',
          cart: {
            top: [],
            bottom: [],
          },
        },
      ];
      this.day2 = [
        {
          id: 1,
          name: 'Morning_2',
          cart: {
            top: [],
            bottom: [],
          },
        },
        {
          id: 1,
          name: 'Noon_2',
          cart: {
            top: [],
            bottom: [],
          },
        },
        {
          id: 1,
          name: 'Night_2',
          cart: {
            top: [],
            bottom: [
              {
                name: 'Order12',
                id: 12,
                price: 100,
                isFixed: true,
              },
            ],
          },
        },
      ];
      this.day3 = [
        {
          id: 1,
          name: 'Morning_3',
          cart: {
            top: [],
            bottom: [],
          },
        },
        {
          id: 1,
          name: 'Noon_3',
          cart: {
            top: [
              {
                name: 'Order8',
                id: 8,
                price: 100,
                isFixed: true,
              },
            ],
            bottom: [],
          },
        },
        {
          id: 1,
          name: 'Night_3',
          cart: {
            top: [],
            bottom: [],
          },
        },
      ];
      this.day4 = [
        {
          id: 1,
          name: 'Morning_4',
          cart: {
            top: [
              {
                name: 'Order2',
                id: 2,
                price: 300,
                isFixed: true,
              },
            ],
            bottom: [],
          },
        },
        {
          id: 1,
          name: 'Noon_4',
          cart: {
            top: [],
            bottom: [],
          },
        },
        {
          id: 1,
          name: 'Night_4',
          cart: {
            top: [],
            bottom: [],
          },
        },
      ];
    },

    canMoveToCart(cartName) {
      if (!cartName) {
        return false;
      }
      const [dayName, timeslot] = cartName.split('.');
      const days = [this.day1, this.day2, this.day3, this.day4];
      let dayObject = null;
      days.some((day) => {
        const found = day.find((d) => d.name === dayName);
        if (found) {
          dayObject = found;
          return true;
        }
        return false;
      });

      return dayObject.cart[timeslot].length < 1;
    },
    handleMove(item) {
      return this.canMoveToCart(item.to.id);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import 'src/assets/scss/style.scss';

.column-title-text2 {
  font-family: 'Nunito Sans', 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif; /* Professional and modern font */
  color: #35495e; /* Subtle, professional color */
  font-size: 18px; /* Appropriate size for subheadings */
  font-weight: 700; /* Bold weight for emphasis */
  padding-top: 5px;
  margin-bottom: 10px; /* Space below the title for separation */
  padding-bottom: 5px; /* Padding to create space within the element */
  border-bottom: 2px solid #5c9df5; /* Colored bottom border for a touch of elegance */
  border-top: 2px solid #5c9df5;
  display: inline-block; /* Ensures the border aligns with the text */
  text-transform: uppercase; /* Uppercase for a more formal appearance */
  letter-spacing: 1px; /* Spacing between letters for readability */
}

/* Optional: Add a hover effect to make it interactive */
.column-title-text2:hover {
  color: #0056b3; /* Darker blue on hover */
  border-color: #0056b3; /* Darker border color on hover */
}

.hr-title-card {
  height: 0.2vh;
  background-color: rgb(175, 206, 253);
  width: 12vw;
}

.ripple-background {
  position: relative;
  overflow: hidden;
}

.submit-button {
  background-color: rgb(94, 211, 94) !important;
  font-weight: bold;
  bottom: 0;
  width: 150px;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  margin-left: 10px;
  float: right;
}

.price-text {
  margin-top: 10px;
  font-size: 15px !important;
  color: rgb(0, 0, 43);
}

// .element-header-title {
//   background-color: ;
// }
.order-card {
  display: flex;
  flex-direction: column;
  width: 13vw;
  border-radius: 2px;
  margin-bottom: 20px;
  border: 1px solid rgb(48, 75, 95);
  background-color: white;
  height: 70px;
  cursor: pointer;

  .card-body {
    padding: 2px !important;
  }
}

.order-card-dis {
  display: flex;
  flex-direction: column;
  width: 13vw;
  border-radius: 2px;
  margin-bottom: 20px;
  border: 1px solid grey;
  background-color: white;
  height: 70px;
  margin-top: 30px; /* Adjust the value as needed */
  cursor: not-allowed;
  .card-body {
    padding: 2px !important;
  }
}

.order-card-dis:hover {
  cursor: not-allowed; /* Ensures cursor is disabled on hover */
}

.element-header {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between !important;
  // margin-bottom: 1rem !important;
  color: rgb(255, 255, 255);
  padding: 5px;
  background-color: rgb(48, 75, 95) !important;
  // border-top-left-radius: 2px;
  // border-top-right-radius: 2px;
}

.element-header-dis {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between !important;
  // margin-bottom: 1rem !important;
  color: rgb(255, 255, 255);
  padding: 5px;
  background-color: grey !important;
  // border-top-left-radius: 2px;
  // border-top-right-radius: 2px;
}

.total-price {
  font-weight: bold;
  bottom: 0;
  // min-width: 150px;
  width: auto;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  margin-left: 10px;
}

.column-title {
  margin-bottom: 2rem;
}

body {
  background: rgb(255, 255, 255) !important;
}

.main-container {
  // margin-top: 10px;
  position: relative;
  min-height: 100vh; /* This ensures the container extends to at least the full height of the viewport */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden !important;
}

.column-title-text {
  font-weight: 900;
  // font-family: Poppins, sans-serif;
  color: rgb(23, 42, 67);
}

.total-row {
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  padding: 0px;
}

.dragArea {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dragArea-timeslot {
  display: flex;
  flex-direction: column;
  height: 60px;
  align-items: center !important;
}
.time-card {
  background-color: rgb(234, 238, 246);
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 7rem;
  align-items: center;
  padding-bottom: 10px;
}

.gradient-background .card-body {
  background-color: #386796 !important;
  // height: 100%;
  // width: 100%;
}

// body{
//   background: #3399ff !important;
// }

.card {
  background-color: transparent !important;
}

.circle {
  position: absolute;
  border-radius: 50%;
  background: white;
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #508fb9;
}

.small {
  width: 200px;
  height: 200px;
  left: -100px;
  bottom: -100px;
}

.medium {
  width: 400px;
  height: 400px;
  left: -200px;
  bottom: -200px;
}

.large {
  width: 600px;
  height: 600px;
  left: -300px;
  bottom: -300px;
}

.xlarge {
  width: 800px;
  height: 800px;
  left: -400px;
  bottom: -400px;
}

.xxlarge {
  width: 1000px;
  height: 1000px;
  left: -500px;
  bottom: -500px;
}

.shade1 {
  opacity: 0.2;
}
.shade2 {
  opacity: 0.5;
}

.shade3 {
  opacity: 0.7;
}

.shade4 {
  opacity: 0.8;
}

.shade5 {
  opacity: 0.9;
}

@keyframes ripple {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
  }
}

::-webkit-scrollbar {
  width: 0.45vw;
  height: 0.45vw;
}

/* Track */
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(143, 143, 143, 0.8);
  // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
</style>
